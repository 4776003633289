<template>
    <div class="row mb-3">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Progress &amp; Grading </h2>
                    </div>
                </div>            
            </div> 
            <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row m-0 p-0">    
                            <div class="form-group col-12 icon-input mb-0">
                                <i class="ti-reload font-xs text-grey-400"></i>
                                <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Term ${cycle}`" disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                        <div class="card-body w-100">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5 class="ml-3">Progress</h5>
                                    <apexchart height="250" width="100%" type="bar" :options="progress.options" :series="progress.series"></apexchart>
                                </div> 
                                <div class="col-lg-12">
                                    <h5 class="ml-3">Grading</h5>
                                    <apexchart height="250" width="100%" type="bar" :options="grading.options" :series="grading.series"></apexchart>
                                </div> 
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        </div>
    </div>          
</template>

<script>
import axios from 'axios';

export default {
    name: `MyCourseProgressGradding`,
    data(){
        return {
            slug: this.$route.params.idCourse,
            cycles: [],
            cycle: '',
            progress:{
                options: {
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                    chart: {
                        id: 'vuechart-example'
                    },
                },
                series: [],
            },
            grading: {
                options: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica",
                        }  
                    },
                },
                series: [],
            },
        }
    },
    created() {
        this.getCycles()
    },
    methods: {
        async getCycles(){
            if(localStorage.getItem(`${this.slug}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.slug}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.slug}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.slug}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.slug}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
            this.getProgres()
            this.getGrading()
        },
        async getProgres() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/progress-grading/progress?slug=${this.slug}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                console.log("DATA PROGRESS", res.data.data.series)
                this.progress = res.data.data
            })
        },
        async getGrading() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/progress-grading/grading?slug=${this.slug}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.grading = res.data.data
            })
        },
        setCycle() {
            this.getProgres()
            this.getGrading()
        },

    }
}
</script>